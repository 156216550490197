import React from 'react';
import './Terms.css';
import logo from './logo_bg_white_round.png';

function Terms() {
  return (
    <div className="Terms">
      <header className="Terms-header">
        <div>
          <div>
            <img src={logo} alt="Logo" />
          </div>
          <h1>Flowallway Platform Terms of Service</h1>
          <p className="Terms-last-updated">Last updated: January 09, 2025</p>
        </div>
      </header>
      
      <div className="Terms-content">
        <div className="Terms-notice">
          <p>
            PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY AS IT CONSTITUTES A LEGALLY 
            BINDING AGREEMENT BETWEEN YOU AND FLOWALLWAY IN RESPECT OF ACCESS AND USE OF 
            FLOWALLWAY'S PLATFORM. THESE TERMS OF SERVICE APPLY TO ALL USERS OF THE PLATFORM.
          </p>
        </div>

        <h2>1. Agreement Scope</h2>
        <p>
          By accessing, browsing, and using the Flowallway Platform, you indicate that you have 
          read and accept these Terms of Service. If you do not accept these Terms of Service, 
          then you may not access or use the Platform or any of its services.
        </p>

        <h2>2. Definitions</h2>
        <div className="Terms-definitions">
          <p><strong>"Platform"</strong> means Flowallway's website and mobile applications.</p>
          <p><strong>"Services"</strong> means all services provided by Flowallway through the Platform.</p>
          <p><strong>"User"</strong> means any person or entity accessing or using the Platform.</p>
          <p><strong>"Personal Account"</strong> means an individual user account.</p>
          <p><strong>"Business Account"</strong> means an account for business entities.</p>
        </div>

        <h2>3. Account Terms</h2>
        <h3>3.1 Account Creation</h3>
        <p>
          To use the Platform, you must create an account. You agree to provide accurate, 
          current, and complete information during the registration process and to update such 
          information to keep it accurate, current, and complete.
        </p>

        <h3>3.2 Account Security</h3>
        <p>
          You are responsible for safeguarding your account password and for any activities 
          or actions under your account. You agree to notify us immediately of any unauthorized 
          use of your account.
        </p>

        <h2>4. Platform Usage</h2>
        <h3>4.1 Permitted Use</h3>
        <p>
          You may use the Platform only for lawful purposes and in accordance with these 
          Terms. You agree not to use the Platform:
        </p>
        <ul>
          <li>In any way that violates any applicable law or regulation</li>
          <li>To transmit any harmful or malicious code</li>
          <li>To attempt to gain unauthorized access to our systems</li>
          <li>To impersonate or attempt to impersonate another user or person</li>
        </ul>

        <h2>5. Business Accounts</h2>
        <p>
          Business accounts are subject to additional verification procedures and must provide:
        </p>
        <ul>
          <li>Valid business registration information</li>
          <li>Business contact details</li>
          <li>Tax identification numbers where applicable</li>
        </ul>

        <h2>6. Intellectual Property</h2>
        <p>
          The Platform and its original content, features, and functionality are owned by 
          Flowallway and are protected by international copyright, trademark, patent, trade 
          secret, and other intellectual property laws.
        </p>

        <h2>7. Privacy and Data Protection</h2>
        <p>
          Your use of the Platform is also governed by our Privacy Policy. Please review our 
          Privacy Policy, which explains how we collect, use, and share your information.
        </p>

        <h2>8. Limitation of Liability</h2>
        <p>
          IN NO EVENT SHALL FLOWALLWAY BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, 
          CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, 
          DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES.
        </p>

        <h2>9. Changes to Terms</h2>
        <p>
          We reserve the right to modify these terms at any time. We will notify users of any 
          material changes via email or through the Platform. Your continued use of the 
          Platform following the posting of changes constitutes acceptance of those changes.
        </p>

        <h2>10. Termination</h2>
        <p>
          We may terminate or suspend your account and bar access to the Platform immediately, 
          without prior notice or liability, under our sole discretion, for any reason 
          whatsoever, including but not limited to a breach of the Terms.
        </p>

        <h2>11. Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us at:</p>
        <p>Email: le.falbobarros@gmail.com</p>
      </div>
    </div>
  );
}

export default Terms;