import React from 'react';
import './Privacy.css';
import logo from './logo_bg_white_round.png';

function Privacy() {
  return (
    <div className="Privacy">
      <header className="Privacy-header">
        <div>
          <div>
            <img src={logo} alt="Logo" />
          </div>
          <h1>Privacy Statement for Flowallway</h1>
          <p className="Privacy-last-updated">Last updated: January 09, 2025</p>
        </div>
      </header>
      
      <div className="Privacy-content">
        <p>
          This Privacy Statement ("Statement") explains how Flowallway ("Flowallway", "we", "us", "our") 
          collects, uses and discloses Personal Data of customers on our website and mobile applications 
          (collectively, the "Flowallway Platform").
        </p>
        
        <p>
          At Flowallway we are committed to protecting the privacy of everyone in our community. 
          By visiting and/or using the Flowallway Platform, you consent to the collection, use, storage, 
          disclosure and transfer of your Personal Data as set out in this Statement.
        </p>

        <h2>What Personal Data We Collect</h2>
        <h3>1. Account Information</h3>
        <p>When you create an account, we collect:</p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Password</li>
          <li>Business information (for business accounts)</li>
        </ul>

        <h3>2. Business Account Data</h3>
        <p>For business accounts, we additionally collect:</p>
        <ul>
          <li>Business name</li>
          <li>Business registration number</li>
          <li>Business address</li>
          <li>Contact information</li>
        </ul>

        <h3>3. Usage Data</h3>
        <p>We automatically collect information about how you use our platform:</p>
        <ul>
          <li>IP address</li>
          <li>Device information</li>
          <li>Browser type and version</li>
          <li>Operating system</li>
          <li>Access times and dates</li>
          <li>Pages visited</li>
        </ul>

        <h2>How We Use Your Personal Data</h2>
        <p>We use your personal data for the following purposes:</p>
        
        <h3>1. Providing Our Services</h3>
        <ul>
          <li>Managing your account</li>
          <li>Processing transactions</li>
          <li>Authenticating access to your account</li>
          <li>Customer support</li>
        </ul>

        <h3>2. Security and Fraud Prevention</h3>
        <ul>
          <li>Protecting against unauthorized access</li>
          <li>Detecting and preventing fraud</li>
          <li>Maintaining network security</li>
        </ul>

        <h3>3. Communication</h3>
        <ul>
          <li>Sending service updates</li>
          <li>Account notifications</li>
          <li>Customer support responses</li>
          <li>Marketing communications (with your consent)</li>
        </ul>

        <h2>Data Sharing and Disclosure</h2>
        <p>We may share your information with:</p>
        <ul>
          <li>Service providers who assist in operating our platform</li>
          <li>Business partners (with your consent)</li>
          <li>Law enforcement when required by law</li>
          <li>Other third parties with your explicit consent</li>
        </ul>

        <h2>Data Security</h2>
        <p>
          We implement appropriate technical and organizational measures to protect your 
          personal data against unauthorized access, alteration, disclosure, or destruction. 
          However, no method of transmission over the Internet is 100% secure.
        </p>

        <h2>Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access your personal data</li>
          <li>Correct inaccurate data</li>
          <li>Request deletion of your data</li>
          <li>Withdraw consent for data processing</li>
          <li>Receive a copy of your data</li>
          <li>Object to certain data processing</li>
        </ul>

        <h2>Contact Us</h2>
        <p>If you have questions about this Privacy Statement or our practices, please contact us:</p>
        <p>Email: le.falbobarros@gmail.com</p>

        <h2>Changes to This Privacy Statement</h2>
        <p>
          We may update this Privacy Statement from time to time. We will notify you of any 
          changes by posting the new Privacy Statement on this page and updating the "Last Updated" 
          date at the top of this Statement.
        </p>
      </div>
    </div>
  );
}

export default Privacy;