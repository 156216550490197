import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import logo from './logo_bg_white_round.png';
import './App.css';
import Terms from './Terms';
import Privacy from './Privacy';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <div className="App">
            <header className="App-header">
              <div>
                <div>
                  <img src={logo} alt="Logo" />
                </div>
                <h1>
                  Welcome to <span className="App-text">Flowallway</span>
                </h1>
              </div>
            </header>
          </div>
        } />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </Router>
  );
}

export default App;